import React from "react";

const Navbar = ({ musicasEscolhidas }) => {
    return (
        <nav className="navbar mb-3 p-3 fundocinza">
            <h2>
                <strong>Montar</strong> Slide
            </h2>
            <a className="btn btn-light ms-auto me-1" href="/homepage">
                Home
            </a>
            <a href="/musicas/cadastrar" className="btn btn-light m-1">
                Cadastrar Música
            </a>
            <button
                className="btn btn-light"
                data-bs-toggle="offcanvas"
                data-bs-target="#painel"
            >
                Músicas Adicionadas
                <span className="badge bg-success ms-3">
                    {musicasEscolhidas.length}
                </span>
            </button>
        </nav>
    );
};

export default Navbar;
