import React from "react";
import { QRCodeSVG } from "qrcode.react"; // Altere aqui
import { useNavigate } from "react-router-dom";
import qrcodepix from "../assets/images/qrcodepix.jpeg";

function PageDoacao() {
  const navigate = useNavigate();

  const codigoPix = "6f40f008-6961-48a9-9926-10b99c6e753e"; // Exemplo de chave PIX fictícia

  return (
    <div className="container mt-5 text-center">
      <h2>Obrigado pela sua contribuição!</h2>
      <p>Se você aprecia o nosso trabalho, considere fazer uma doação.</p>

      {/* QR Code fictício */}
      <img
        src={qrcodepix}
        alt="QR Code PIX"
        className="img-fluid mb-3"
        style={{ width: "300px", height: "px" }}
      />
      <p className="mt-3">Use o QR Code acima para fazer sua doação!</p>

      <h5>Código PIX:</h5>
      <input
        type="text"
        value={codigoPix}
        readOnly
        className="form-control text-center"
        onFocus={(e) => e.target.select()} // Seleciona o texto no foco
      />
      <button
        onClick={() => navigator.clipboard.writeText(codigoPix)}
        className="btn btn-success mt-2"
      >
        Copiar Código PIX
      </button>

      <button onClick={() => navigate(-1)} className="btn btn-secondary mt-3">
        Voltar
      </button>
    </div>
  );
}

export default PageDoacao;
