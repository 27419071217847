import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import CardCriarSlide from "../components/CardCriarSlide";
import CardCadastrarMusica from "../components/CardCadastrarMusica";
import Carousel from "../components/Carousel";

function HomePage() {
    const [username, setUsername] = useState("");
    useEffect(() => {
        // Supondo que o nome do usuário esteja armazenado no localStorage
        const storedUsername = localStorage.getItem("username");
        if (storedUsername) {
            setUsername(storedUsername);
        }
        console.log("Username obtido do localStorage:", storedUsername);
    }, []);
    return (
        <div>
            <Header />
            <Carousel />
            <div className="container mb-5">
                <h2 className="text-center tf-2">
                    <strong>Bem Vindo {username}</strong> ao sistema de criação
                    de slides de músicas para missa
                </h2>
            </div>
            <div className="container mb-5">
                <div className="row row-cols g-3">
                    <CardCriarSlide />
                    <CardCadastrarMusica />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default HomePage;
