// Buscar.js
import React, { useState } from 'react';

function Buscar({ onBuscar }) {
    const [palavra, setPalavra] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onBuscar(palavra);
    };

    return (
        <div className="container">
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-12 col-md-8">
                        <label className="mb-2" htmlFor="palavrasChave">Buscar por música:</label>
                        <input
                            className="form-control"
                            type="search"
                            id="palavrasChave"
                            placeholder="Digite uma palavra-chave para buscar a música."
                            value={palavra}
                            onChange={(e) => setPalavra(e.target.value)}
                        />
                    </div>
                    <div className="col-12 col-md-4 mt-4 d-flex align-items-center">
                        <input
                            className="border rounded-1 verdec ps-2 pe-2 btn btn-outline-light text-dark"
                            type="submit"
                            value="Buscar"
                        />
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Buscar;
