// src/pages/PoliticasUso.js

import React from "react";
import Header from "../components/Header";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const PoliticasUso = () => {
  return (
    <div>
      <Header />
      <Nav />
      <div className="container">
        <h2 className="text-center mt-5">Políticas de Uso</h2>
        <p>
          Bem-vindo à nossa aplicação. Ao utilizar nosso serviço, você concorda
          com as seguintes políticas de uso. Leia estas políticas cuidadosamente
          antes de começar a usar o serviço.
        </p>
        <h3>1. Aceitação dos Termos</h3>
        <p>
          Ao acessar e utilizar nossa aplicação, você concorda em cumprir e
          ficar vinculado aos termos e condições aqui descritos. Se não
          concordar com estes termos, você não deverá usar o aplicativo.
        </p>
        <h3>2. Uso Permitido</h3>
        <p>
          Você concorda em usar a aplicação apenas para fins legais e de acordo
          com as políticas estabelecidas.
        </p>
        <h3>3. Proteção de Dados</h3>
        <p>
          Nós adotamos medidas adequadas para proteger suas informações pessoais
          em conformidade com a legislação aplicável.
        </p>
        <h3>4. Alterações nas Políticas</h3>
        <p>
          Reservamo-nos o direito de modificar estas políticas de uso a qualquer
          momento. Notificaremos sobre quaisquer alterações materialmente
          significativas através do aplicativo.
        </p>
        <p>Obrigado por usar nossa aplicação!</p>
      </div>
      <div className="d-flex justify-content-center">
        <Link to="/" className="btn btn-secondary mx-2 m-auto">
          Voltar para o início
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default PoliticasUso;
