import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logotipo from "../assets/images/logotipo.png";
import LogoutButton from "./LogoutButton";
import UserInfoOffcanvas from "./UserInfoOffcanvas"; // importe seu novo componente

function Header({ children }) {
  const location = useLocation();
  const isAuthenticated = !!localStorage.getItem("token");
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [username, setUsername] = useState("");
  useEffect(() => {
    const storedUsername = localStorage.getItem("username");
    if (storedUsername) {
      setUsername(storedUsername);
    }
    console.log("Username obtido do localStorage:", storedUsername);
  }, []);

  const toggleOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
  };

  return (
    <div>
      <nav className="navbar fundoverde">
        <div className="container d-flex justify-content-between align-items-center">
          <div>
            <Link to="/homepage">
              <img
                src={logotipo}
                width="80px"
                className="img-thumbnail rounded-circle navbar-brand"
                alt="Logotipo"
              />
            </Link>
          </div>
          <h1 className="navbar-brand text-white">Slide Para Missa</h1>
          <div>
            {children}
            {isAuthenticated && (
              <>
                {location.pathname !== "/login" &&
                  location.pathname !== "/" &&
                  location.pathname !== "/cadastrar"}
                <button
                  className="btn btn-light rounded"
                  onClick={toggleOffcanvas}
                >
                  {username}
                </button>
              </>
            )}
          </div>
        </div>
      </nav>

      {/* Offcanvas de informações do usuário */}
      <UserInfoOffcanvas show={showOffcanvas} onClose={toggleOffcanvas} />
    </div>
  );
}

export default Header;
