import React from "react";
import { useNavigate } from "react-router-dom";
import { API_URL_ORIGINAL } from "../config/apiConfig"; // Certifique-se de importar a configuração correta

const UserInfoOffcanvas = ({ show, onClose }) => {
  const navigate = useNavigate();
  const username = localStorage.getItem("username");
  const email = localStorage.getItem("email");

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("email");
    navigate("/login"); // Redireciona para a página de login
  };

  return (
    <div
      className={`offcanvas offcanvas-end ${show ? "show" : ""} rounded`}
      style={{ visibility: show ? "visible" : "hidden" }}
    >
      <div className="offcanvas-header">
        <h5 className="offcanvas-title">Informações do usuário</h5>
        <button
          type="button"
          className="btn-close"
          onClick={onClose}
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body">
        <p>
          <strong>Username:</strong> {username}
        </p>
        <p>
          <strong>Email:</strong> {email}
        </p>
        <button className="btn btn-danger" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
};

export default UserInfoOffcanvas;
