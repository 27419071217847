import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL_ORIGINAL, API_URL_TEST } from "../config/apiConfig";
import Header from "../components/Header";

function RecuperarSenha() {
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage("");

        try {
            const response = await fetch(
                `${API_URL_ORIGINAL}/auth/recuperar-senha`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ email }),
                },
            );

            const data = await response.json();

            if (response.ok) {
                setIsSuccess(true);
                setMessage(
                    "Um e-mail com instruções para redefinir sua senha foi enviado.",
                );
                // Redirecionar para login após 3 segundos
                setTimeout(() => {
                    navigate("/login");
                }, 3000);
            } else {
                setIsSuccess(false);
                setMessage(data.message || "Erro ao recuperar senha.");
            }
        } catch (error) {
            console.error("Erro:", error);
            setIsSuccess(false);
            setMessage("Erro ao processar sua solicitação. Tente novamente.");
        }
    };

    return (
        <div>
            <Header />
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <h2 className="text-center mb-4">
                                    Recuperar Senha
                                </h2>
                                {message && (
                                    <div
                                        className={`alert ${
                                            isSuccess
                                                ? "alert-success"
                                                : "alert-danger"
                                        }`}
                                        role="alert"
                                    >
                                        {message}
                                    </div>
                                )}
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label
                                            htmlFor="email"
                                            className="form-label"
                                        >
                                            Digite seu e-mail:
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            value={email}
                                            onChange={(e) =>
                                                setEmail(e.target.value)
                                            }
                                            required
                                        />
                                        <div className="form-text">
                                            Enviaremos um link para recuperação
                                            de senha no seu e-mail.
                                        </div>
                                    </div>
                                    <div className="d-grid gap-2">
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Enviar Link de Recuperação
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={() => navigate("/login")}
                                        >
                                            Voltar para Login
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RecuperarSenha;
