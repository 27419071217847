import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LoginPage from "./pages/LoginPage2"; // Página de login
import Home from "./pages/Home"; // Para usuários não autenticados
import HomePage from "./pages/HomePage"; // Para usuários autenticados
import MusicasEncontrar from "./pages/MusicasEncontrar"; // Página para encontrar músicas
import MusicaCadastrar from "./pages/MusicaCadastrar"; // Página para cadastrar músicas
import PrivateRoute from "./components/PrivateRoute"; // Componente para proteger rotas
import PoliticasUso from "./pages/PoliticasUso"; // Página de políticas de uso
import Contatos from "./pages/Contatos"; // Página de contatos
import Sobre from "./pages/Sobre"; // Página sobre
import CadastroUsuario from "./pages/CadastroUsuario"; // Página para cadastro de usuário
import PageDoacao from "./pages/PageDoacao"; // importe o componente PageDoacao
import RecuperarSenha from "./pages/RecuperarSenha";
import RedefinirSenha from "./pages/RedefinirSenha";

function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />{" "}
        <Route path="/recuperar-senha" element={<RecuperarSenha />} />{" "}
        <Route path="/redefinir-senha" element={<RedefinirSenha />} />{" "}
        <Route
          path="/homepage"
          element={
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          }
        />{" "}
        {/* Página para usuários autenticados */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/politicas-de-uso" element={<PoliticasUso />} />
        <Route path="/contatos" element={<Contatos />} />
        <Route path="/sobre" element={<Sobre />} />
        <Route
          path="/musicas/encontrar"
          element={
            <PrivateRoute>
              <MusicasEncontrar />
            </PrivateRoute>
          }
        />
        <Route
          path="/musicas/cadastrar"
          element={
            <PrivateRoute>
              <MusicaCadastrar />
            </PrivateRoute>
          }
        />
        <Route path="/cadastrar" element={<CadastroUsuario />} />{" "}
        {/* Página de cadastro */}
        <Route path="*" element={<Navigate to="/" />} />{" "}
        {/* Redireciona para a Home */}
        <Route path="/doacao" element={<PageDoacao />} />
      </Routes>
    </Router>
  );
}

export default AppRoutes;
