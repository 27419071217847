// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
  display: flex;
  height: 100vh; /* Altura total da tela */
}

.login-form {
  flex: 1 1; /* O lado direito da tela ocupará o espaço disponível */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centraliza o conteúdo verticalmente */
  padding: 20px; /* Adiciona espaçamento */
  background: rgba(255, 255, 255, 0.8); /* Fundo branco com opacidade */
}

.background {
  flex: 1 1; /* O componente de fundo ocupará o outro lado da tela */
  position: relative; /* Se necessário para posicionar elementos no fundo */
}`, "",{"version":3,"sources":["webpack://./src/styles/LoginPage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa,EAAE,yBAAyB;AAC1C;;AAEA;EACE,SAAO,EAAE,uDAAuD;EAChE,aAAa;EACb,sBAAsB;EACtB,uBAAuB,EAAE,wCAAwC;EACjE,aAAa,EAAE,yBAAyB;EACxC,oCAAoC,EAAE,+BAA+B;AACvE;;AAEA;EACE,SAAO,EAAE,uDAAuD;EAChE,kBAAkB,EAAE,qDAAqD;AAC3E","sourcesContent":[".login-container {\n  display: flex;\n  height: 100vh; /* Altura total da tela */\n}\n\n.login-form {\n  flex: 1; /* O lado direito da tela ocupará o espaço disponível */\n  display: flex;\n  flex-direction: column;\n  justify-content: center; /* Centraliza o conteúdo verticalmente */\n  padding: 20px; /* Adiciona espaçamento */\n  background: rgba(255, 255, 255, 0.8); /* Fundo branco com opacidade */\n}\n\n.background {\n  flex: 1; /* O componente de fundo ocupará o outro lado da tela */\n  position: relative; /* Se necessário para posicionar elementos no fundo */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
