import React from "react";
import "../styles/backgroundanimado.css";

function BackgroundAnimado() {
  return (
    <div className="d-md-block d-none">
      <ul className="background">
        <li className="d-flex display-5 justify-content-center align-items-center">
          <i className="bi bi-music-note text-center"></i>
        </li>
        <li className="d-flex display-5 justify-content-center align-items-center">
          <i className="bi bi-music-note text-center"></i>
        </li>
        <li className="d-flex display-5 justify-content-center align-items-center">
          <i className="bi bi-music-note text-center"></i>
        </li>
        <li className="d-flex display-5 justify-content-center align-items-center">
          <i className="bi bi-music-note text-center"></i>
        </li>
        <li className="d-flex display-5 justify-content-center align-items-center">
          <i className="bi bi-music-note text-center"></i>
        </li>
        <li className="d-flex display-5 justify-content-center align-items-center">
          <i className="bi bi-music-note text-center"></i>
        </li>
        <li className="d-flex display-5 justify-content-center align-items-center">
          <i className="bi bi-music-note text-center"></i>
        </li>
        <li className="d-flex display-5 justify-content-center align-items-center">
          <i className="bi bi-music-note text-center"></i>
        </li>
        <li className="d-flex display-5 justify-content-center align-items-center">
          <i className="bi bi-music-note text-center"></i>
        </li>
        <li className="d-flex display-5 justify-content-center align-items-center">
          <i className="bi bi-music-note text-center"></i>
        </li>
      </ul>
    </div>
  );
}

export default BackgroundAnimado;
