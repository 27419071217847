import slide1 from '../assets/images/slide1.png';
import slide2 from '../assets/images/slide2.png';
import slide3 from '../assets/images/slide3.png';

function Carousel() {
  return (
    <div className="container py-5">
      <div id="ads" className="d-none d-sm-block carousel slide carousel-fade" data-bs-ride="carousel">
        <div className="carousel-inner">
          {/* Indicadores do Carrossel */}
          <div className="carousel-indicators">
            <button type="button" className="active" data-bs-target="#ads" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#ads" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#ads" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div>

          {/* Item 1 */}
          <div className="carousel-item active">
            <img src={slide1} alt="Slide 1" width="1280" height="420" className="d-block w-100" />
            <div className="carousel-caption">
              <h5>Crie seus slides com suas músicas em minutos.</h5>
              <p>Com esse sistema você pode criar os slides das letras das músicas e fazer a assembléia cantar com você.</p>
            </div>
          </div>

          {/* Item 2 */}
          <div className="carousel-item">
            <img src={slide2} alt="Slide 2" width="1280" height="420" className="d-block w-100" />
            <div className="carousel-caption">
              <h5 className="text-warning">Um slide em suas mãos em pouquíssimo tempo</h5>
              <p>Crie slides com letras de músicas, em pouco tempo ele fica pronto. Basta escolher as músicas e incluir nos slides.</p>
            </div>
          </div>

          {/* Item 3 */}
          <div className="carousel-item">
            <img src={slide3} alt="Slide 3" width="1280" height="420" className="d-block w-100" />
            <div className="carousel-caption">
              <h5>Projete suas músicas.</h5>
              <p>Projete as letras das músicas do evento de sua igreja e deixe as pessoas cantarem com você.</p>
            </div>
          </div>

          {/* Controles do Carrossel */}
          <button className="carousel-control-prev" type="button" data-bs-target="#ads" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Anterior</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#ads" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Próximo</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Carousel;
