import React from "react";
import "./styles/App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import AppRoutes from "./AppRoutes";

// Importação do WebSocketClient
import "./services/WebSocketClient";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <AppRoutes />
      </header>
    </div>
  );
}

export default App;
