// LogoutButton.js

import React from "react";
import { useNavigate } from "react-router-dom";

function LogoutButton() {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Remove os dados de autenticação do localStorage
    localStorage.removeItem("token"); // Remove o token
    localStorage.removeItem("username"); // Opcional: Remove o nome de usuário
    localStorage.removeItem("email"); // Opcional: Remove o email

    // Redirect to home
    navigate("/home");
  };

  return (
    <button onClick={handleLogout} className="btn btn-outline-danger">
      Logout
    </button>
  );
}

export default LogoutButton;
