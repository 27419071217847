import React from "react";
import { Navigate } from "react-router-dom";

function PrivateRoute({ children }) {
  const isAuthenticated = !!localStorage.getItem("token"); // Verifica se o token está no localStorage

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return children; // Retorna os filhos se autenticados
}

export default PrivateRoute;
