import React from "react";
import Footer from "../components/Footer";
import Carousel from "../components/Carousel";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";
import CardBeneficios from "../components/CardBeneficios";

function Home() {
    const navigate = useNavigate();

    const handleLogin = () => {
        navigate("/login");
    };

    const handleRegister = () => {
        navigate("/cadastrar");
    };

    return (
        <div>
            <Header>
                <button onClick={handleLogin} className="btn btn-light mx-2">
                    Entrar
                </button>
                <button onClick={handleRegister} className="btn btn-light mx-2">
                    Cadastre-se
                </button>
            </Header>
            <Carousel />
            <div className="container">
                <h2 className="text-center tf-2">
                    <strong>Bem Vindo</strong> ao sistema de criação de slides
                    de músicas para missa
                </h2>
            </div>
            <div className="container">
                <div className="col-12 mt-3 mb-5 p-2 text-center">
                    <p className="m-3 tf-1">
                        O sistema de criação de slides serve para facilitar a
                        inclusão de músicas no arquivo pptx. Para usar, basta
                        pesquisar a música no banco de dados e clicar no botão
                        incluir, que ela será incluída no slide. Caso a música
                        pesquisada não seja encontrada, você pode cadastrá-la.
                    </p>
                </div>
            </div>

            {/* Seção do vídeo com texto convidativo */}
            <div className="container mb-5">
                <div className="text-center mb-4">
                    <h3>Veja como é fácil usar o Slide Para Missa!</h3>
                    <p>
                        Assista ao vídeo abaixo e descubra como criar seus
                        slides de forma rápida e prática
                    </p>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-10">
                        <div className="ratio ratio-16x9">
                            <iframe
                                src="https://www.youtube.com/embed/-ufpyqLMmlQ"
                                title="Tutorial Slide Para Missa"
                                allowFullScreen
                                className="rounded shadow"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <CardBeneficios />
            <Footer />
        </div>
    );
}

export default Home;
