import axios from "axios";
import { API_URL_TEST } from "../config/apiConfig";

export const buscarMusicas = async (palavraChave) => {
    try {
        console.log(`Buscando músicas com palavras-chave: ${palavraChave}`);
        const response = await axios.get(
            `${API_URL_TEST}=${palavraChave}`,
        );

        // Verifique o tipo de resposta
        console.log("Tipo de resposta:", response.headers["content-type"]);
        console.log("Dados completos da resposta:", response.data);

        if (response.headers["content-type"].includes("text/html")) {
            // Extrai músicas usando regex
            const musicas = response.data.match(/<li>.*?<\/li>/gs);
            console.log("Músicas extraídas do HTML:", musicas);
            return musicas;
        } else {
            // Caso seja JSON
            console.log("Músicas no formato JSON:", response.data);
            return response.data;
        }
    } catch (error) {
        console.error("Erro ao buscar músicas:", error);
        throw error;
    }
};
