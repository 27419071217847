import React, { useState } from "react";
import Header from "../components/Header";
import Buscar from "../components/Buscar";
import Navbar from "../components/Navbar";
import { buscarMusicas } from "../services/MusicaServices";
import { Link } from "react-router-dom";
import axios from "axios";
import Footer from "../components/Footer";
import { API_URL_ORIGINAL, API_URL_TEST } from "../config/apiConfig";
import { useNavigate } from "react-router-dom";

const MusicasEncontrar = () => {
    const [musicas, setMusicas] = useState([]);
    const [mensagem, setMensagem] = useState("Músicas Encontradas");
    const [musicasEscolhidas, setMusicasEscolhidas] = useState([]);
    const [mensagemAdicao, setMensagemAdicao] = useState("");
    const navigate = useNavigate();

    const handleBuscaMusicas = async (palavraChave) => {
        const token = localStorage.getItem("token");

        try {
            const resultado = await axios.get(`${API_URL_ORIGINAL}/musicas`, {
                params: { palavraChave },
                headers: {
                    Authorization: `Bearer ${token}`, // Incluindo o token no cabeçalho
                },
            });
            if (
                resultado.data &&
                resultado.data.musicas &&
                resultado.data.musicas.length
            ) {
                setMusicas(resultado.data.musicas);
                setMensagem("Músicas Encontradas");
            } else {
                setMensagem("Nenhuma Música Encontrada");
                setMusicas([]);
            }
        } catch (error) {
            console.error("Erro ao buscar músicas:", error);
            setMensagem("Nenhuma Música Encontrada");
            setMusicas([]);
        }
    };

    const adicionarMusica = (musica) => {
        setMusicasEscolhidas([...musicasEscolhidas, musica]);
        setMensagemAdicao(
            `A música "${musica.titulo}" foi adicionada à lista.`,
        );
        setTimeout(() => setMensagemAdicao(""), 3000);
    };

    const removerMusica = (index) => {
        setMusicasEscolhidas(musicasEscolhidas.filter((_, i) => i !== index));
    };

    const removerTodasMusicas = () => {
        setMusicasEscolhidas([]);
    };

    const criarSlide = async () => {
        const token = localStorage.getItem("token");

        try {
            const payload = {
                musicasEscolhidas: musicasEscolhidas.map((musica) => ({
                    titulo: musica.titulo,
                    autor: musica.autor,
                    estrofe1: musica.estrofe1,
                    estrofe2: musica.estrofe2,
                    estrofe3: musica.estrofe3,
                    estrofe4: musica.estrofe4,
                    estrofe5: musica.estrofe5,
                    refrao: musica.refrao,
                    momento: musica.momento,
                })),
            };

            const resposta = await axios.post(
                `${API_URL_ORIGINAL}/criarSlide`,
                payload,
                {
                    responseType: "blob",
                    headers: {
                        Authorization: `Bearer ${token}`, // Incluindo o token no cabeçalho
                    },
                },
            );

            const url = window.URL.createObjectURL(new Blob([resposta.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "ArquivoPronto.pptx");
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error("Erro ao criar o slide:", error);
            alert("Erro ao gerar o slide");
        }
        navigate("/doacao");
    };

    return (
        <div>
            <Header />
            <Navbar musicasEscolhidas={musicasEscolhidas} />
            <Buscar onBuscar={handleBuscaMusicas} />
            <div className="container mb-5">
                <div className="row">
                    <div className="col col-sm-4">
                        <h4>Músicas Encontradas:</h4>
                    </div>
                    {musicas.length > 0 ? (
                        <div className="musicas-container d-flex justify-content-around">
                            {musicas.map((musica, index) => (
                                <div
                                    key={index}
                                    className="musica-item col-12 col-md-3 fundocinza h400 mb-3 ms-1 border rounded position-relative overflow-hidden"
                                >
                                    <div className="position-absolute overflow-auto p-3">
                                        <h5 className="fontverde">
                                            {musica.titulo ||
                                                "Título não disponível"}
                                        </h5>
                                        <p>
                                            <strong>Autor:</strong>{" "}
                                            {musica.autor ||
                                                "Autor não disponível"}
                                        </p>
                                        {musica.estrofe1 && (
                                            <p>
                                                <strong>Estrofe 1:</strong>{" "}
                                                {musica.estrofe1}
                                            </p>
                                        )}
                                        {musica.estrofe2 && (
                                            <p>
                                                <strong>Estrofe 2:</strong>{" "}
                                                {musica.estrofe2}
                                            </p>
                                        )}
                                        {musica.estrofe3 && (
                                            <p>
                                                <strong>Estrofe 3:</strong>{" "}
                                                {musica.estrofe3}
                                            </p>
                                        )}
                                        {musica.estrofe4 && (
                                            <p>
                                                <strong>Estrofe 4:</strong>{" "}
                                                {musica.estrofe4}
                                            </p>
                                        )}
                                        {musica.estrofe5 && (
                                            <p>
                                                <strong>Estrofe 5:</strong>{" "}
                                                {musica.estrofe5}
                                            </p>
                                        )}
                                        {musica.refrao && (
                                            <p>
                                                <strong>Refrão:</strong>{" "}
                                                {musica.refrao}
                                            </p>
                                        )}
                                        <p>
                                            <strong>Momento:</strong>{" "}
                                            {musica.momento ||
                                                "Momento não disponível"}
                                        </p>
                                    </div>
                                    <div className="position-absolute bottom-0 start-0 sticky-bottom fundoverde d-flex justify-content-center align-items-center largura">
                                        <button
                                            className="btn fontverde text-center"
                                            onClick={() =>
                                                adicionarMusica(musica)
                                            }
                                        >
                                            <i className="bi bi-plus-circle fontverde">
                                                Adicionar Música
                                            </i>
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div>
                            <p>{mensagem}</p>
                            <p>
                                Se não encontrou a música desejada, você pode
                                cadastrá-la.
                            </p>
                            <Link
                                to="/musicas/cadastrar"
                                className="btn btn-primary"
                            >
                                Cadastrar Música
                            </Link>
                        </div>
                    )}
                </div>
            </div>
            <Footer />

            <div
                className="offcanvas offcanvas-end fundocinza"
                id="painel"
                tabIndex="-1"
                data-bs-scroll="true"
                data-bs-backdrop="true"
                style={{ "--bs-offcanvas-width": "500px" }}
            >
                <div className="offcanvas-header fundoverde d-flex align-item-center">
                    <h5 className="offcanvas-title text-light text-center">
                        <strong>Músicas</strong> Selecionadas
                    </h5>
                    <button
                        className="btn-close bg-light me-2"
                        data-bs-dismiss="offcanvas"
                    ></button>
                </div>
                <div className="offcanvas-body">
                    {musicasEscolhidas.length > 0 ? (
                        <>
                            {musicasEscolhidas.map((musica, index) => (
                                <div key={index} className="text-center mb-3">
                                    <h5>
                                        {musica.titulo ||
                                            "Título não disponível"}
                                    </h5>
                                    <p>
                                        <strong>Autor:</strong>{" "}
                                        {musica.autor || "Autor não disponível"}
                                    </p>
                                    {musica.estrofe1 && (
                                        <>
                                            <p>
                                                <strong>Estrofe 1:</strong>{" "}
                                                {musica.estrofe1}
                                            </p>
                                            {musica.refrao && (
                                                <p>
                                                    <strong>Refrão:</strong>{" "}
                                                    {musica.refrao}
                                                </p>
                                            )}
                                        </>
                                    )}
                                    {musica.estrofe2 && (
                                        <>
                                            <p>
                                                <strong>Estrofe 2:</strong>{" "}
                                                {musica.estrofe2}
                                            </p>
                                            {musica.refrao && (
                                                <p>
                                                    <strong>Refrão:</strong>{" "}
                                                    {musica.refrao}
                                                </p>
                                            )}
                                        </>
                                    )}
                                    {musica.estrofe3 && (
                                        <>
                                            <p>
                                                <strong>Estrofe 3:</strong>{" "}
                                                {musica.estrofe3}
                                            </p>
                                            {musica.refrao && (
                                                <p>
                                                    <strong>Refrão:</strong>{" "}
                                                    {musica.refrao}
                                                </p>
                                            )}
                                        </>
                                    )}
                                    {musica.estrofe4 && (
                                        <>
                                            <p>
                                                <strong>Estrofe 4:</strong>{" "}
                                                {musica.estrofe4}
                                            </p>
                                            {musica.refrao && (
                                                <p>
                                                    <strong>Refrão:</strong>{" "}
                                                    {musica.refrao}
                                                </p>
                                            )}
                                        </>
                                    )}
                                    {musica.estrofe5 && (
                                        <>
                                            <p>
                                                <strong>Estrofe 5:</strong>{" "}
                                                {musica.estrofe5}
                                            </p>
                                            {musica.refrao && (
                                                <p>
                                                    <strong>Refrão:</strong>{" "}
                                                    {musica.refrao}
                                                </p>
                                            )}
                                        </>
                                    )}
                                    <button
                                        className="btn btn-danger mt-2"
                                        onClick={() => removerMusica(index)}
                                    >
                                        Excluir
                                    </button>
                                </div>
                            ))}
                            <button
                                className="btn btn-danger mt-3"
                                onClick={removerTodasMusicas}
                            >
                                Excluir Todas as Músicas
                            </button>
                            <button
                                className="btn btn-success m-3 mt-3"
                                onClick={criarSlide}
                            >
                                Criar Slide
                            </button>
                        </>
                    ) : (
                        <p>Nenhuma música selecionada.</p>
                    )}
                </div>
            </div>
            {mensagemAdicao && (
                <div className="alert alert-success position-fixed bottom-0 end-0 m-3">
                    {mensagemAdicao}
                </div>
            )}
        </div>
    );
};

export default MusicasEncontrar;
