class WebSocketClient {
  constructor(url) {
    this.client = new WebSocket(url);

    this.client.onopen = (event) => {
      console.log("Conexão estabelecida", event);
    };

    this.client.onmessage = (message) => {
      console.log("Mensagem recebida:", message.data);
    };

    this.client.onerror = (error) => {
      console.error("Erro de WebSocket:", error);
    };

    this.client.onclose = (event) => {
      console.log("Conexão fechada:", event);
    };
  }
}

// Inicialize o WebSocketClient com a URL correta
const webSocketClient = new WebSocketClient(
  process.env.REACT_APP_WEBSOCKET_URL || "wss://slideparamissa.com.br/ws",
);
