import { Link } from "react-router-dom"; // Importa o Link do react-router-dom

function CardCadastrarMusica() {
    return (
        <div className="col">
            <div className="p-5 bg-cinza position-relative text-center h200 d-flex flex-column justify-content-between rounded">
                <span className="fundoverde rounded-circle p-4 top-0 translate-middle start-50 position-absolute border border-light">
                    <i className="bi bi-database-add tf-2 text-light"></i>
                </span>
                <p>
                    Aqui você pode cadastrar a música no banco de dados caso
                    você não encontre-a na busca
                </p>
                <Link className="btn btn-light" to="/musicas/cadastrar">
                    Cadastrar <strong>Música</strong>
                </Link>
            </div>
        </div>
    );
}

export default CardCadastrarMusica;
