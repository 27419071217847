import { Link } from 'react-router-dom';

const CardCriarSlide = () => (
    <div className="col">
        <div className="p-5 bg-cinza position-relative text-center h200 d-flex flex-column justify-content-between border rounded">
            <span className="fundoverde rounded-circle p-4 top-0 translate-middle start-50 position-absolute border border-light">
                <i className="bi bi-music-note-list tf-2 text-light"></i>
            </span>
            <p>Aqui você pode escolher as músicas que serão adicionadas ao arquivo.</p>
            <Link className="btn btn-light" to="/musicas/encontrar">
                Criar <strong>Slide</strong>
            </Link>
        </div>
    </div>
);

export default CardCriarSlide;