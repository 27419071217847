import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL_ORIGINAL, API_URL_TEST } from "../config/apiConfig";
import Header from "../components/Header";

function CadastroUsuario() {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [mensagem, setMensagem] = useState("");
  const navigate = useNavigate();

  const handleCadastro = async (event) => {
    event.preventDefault();
    setMensagem("");

    if (password !== confirmPassword) {
      setMensagem("As senhas não correspondem.");
      return;
    }

    try {
      const response = await fetch(API_URL_ORIGINAL + "/auth/cadastrar", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        setMensagem(data.message);
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        const errorData = await response.json();
        setMensagem(errorData.message || "Erro ao cadastrar usuário.");
      }
    } catch (error) {
      console.error("Erro de cadastro:", error);
      setMensagem("Erro de rede. Tente novamente.");
    }
  };

  return (
    <div>
      <Header />
      <div className="container mt-5">
        <h2 className="text-center mb-4">Cadastro de Usuário</h2>
        {mensagem && <p className="text-danger text-center">{mensagem}</p>}
        <form
          onSubmit={handleCadastro}
          className="bg-light p-4 rounded shadow-sm"
        >
          <div className="mb-3">
            <label className="form-label">Nome de Usuário:</label>
            <input
              type="text"
              className="form-control"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Email:</label>
            <input
              type="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Senha:</label>
            <input
              type="password"
              className="form-control"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Confirmar Senha:</label>
            <input
              type="password"
              className="form-control"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-success w-100">
            Cadastrar
          </button>
        </form>
        <div className="text-center mt-3">
          <p>Já tem uma conta?</p>
          <button className="btn btn-link" onClick={() => navigate("/login")}>
            Ir para Login
          </button>
        </div>
      </div>
    </div>
  );
}

export default CadastroUsuario;
