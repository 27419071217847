// src/pages/Contatos.js

import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const Contatos = () => {
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [mensagem, setMensagem] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    // Aqui você pode adicionar lógica para enviar os dados para um backend ou uma API
    alert("Sugestão enviada com sucesso!");
    // Resetar o formulário
    setNome("");
    setEmail("");
    setMensagem("");
  };

  return (
    <div>
      <Header />
      <div className="container">
        <h2 className="text-center mt-5">Contatos</h2>
        <p className="text-center">
          Por favor, deixe suas sugestões ou comentários abaixo:
        </p>
        <form onSubmit={handleSubmit} className="mt-4">
          <div className="mb-3">
            <label className="form-label">Nome</label>
            <input
              type="text"
              className="form-control"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Email</label>
            <input
              type="email"
              className="form-control"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Mensagem</label>
            <textarea
              className="form-control"
              rows="5"
              value={mensagem}
              onChange={(e) => setMensagem(e.target.value)}
              required
            ></textarea>
          </div>
          <button type="submit" className="btn btn-primary">
            Enviar
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default Contatos;
