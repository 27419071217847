function Footer() {
  return (
    <footer className="fundoverde text-center p-2 mt-3 bottom-0 p-absolute">
      <p className="text-light">
        <strong className="me-3">
          &copy;2024 Richarlisom Martins de Souza
        </strong>
        Todos os direitos reservados
      </p>
    </footer>
  );
}

export default Footer;
