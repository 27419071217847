import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Header from "../components/Header";
import { API_URL_ORIGINAL, API_URL_TEST } from "../config/apiConfig";

const CadastrarMusica = () => {
  const [formData, setFormData] = useState({
    titulo: "",
    autor: "",
    estrofe1: "",
    estrofe2: "",
    estrofe3: "",
    estrofe4: "",
    estrofe5: "",
    refrao: "",
    momento: "",
  });

  const [mensagem, setMensagem] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Dados do formulário sendo enviados:", formData);

    // Obtendo o token do localStorage
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `${API_URL_ORIGINAL}/cadastrar`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Incluindo o token no cabeçalho
          },
        },
      );

      console.log("Resposta do servidor:", response);
      if (response.status === 200) {
        setMensagem("Música cadastrada com sucesso!");
        setFormData({
          titulo: "",
          autor: "",
          estrofe1: "",
          estrofe2: "",
          estrofe3: "",
          estrofe4: "",
          estrofe5: "",
          refrao: "",
          momento: "",
        });
      } else {
        setMensagem("Erro ao cadastrar música.");
      }
    } catch (error) {
      console.error("Erro ao cadastrar música:", error);
      console.log(
        "Erro detalhado:",
        error.response ? error.response.data : error.message,
      );
      setMensagem("Erro ao cadastrar música.");
    }
  };

  return (
    <div>
      <Header />
      <div className="container mt-5">
        <h2 className="text-center mb-4">Cadastrar Música</h2>
        <form onSubmit={handleSubmit} className="needs-validation" noValidate>
          <div className="row g-3">
            <div className="col-md-6">
              <label className="form-label">Título:</label>
              <input
                type="text"
                name="titulo"
                value={formData.titulo}
                onChange={handleChange}
                className="form-control"
                required
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">Autor:</label>
              <input
                type="text"
                name="autor"
                value={formData.autor}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="col-12">
              <label className="form-label">Estrofe 1:</label>
              <textarea
                name="estrofe1"
                value={formData.estrofe1}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="col-12">
              <label className="form-label">Estrofe 2:</label>
              <textarea
                name="estrofe2"
                value={formData.estrofe2}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="col-12">
              <label className="form-label">Estrofe 3:</label>
              <textarea
                name="estrofe3"
                value={formData.estrofe3}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="col-12">
              <label className="form-label">Estrofe 4:</label>
              <textarea
                name="estrofe4"
                value={formData.estrofe4}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="col-12">
              <label className="form-label">Estrofe 5:</label>
              <textarea
                name="estrofe5"
                value={formData.estrofe5}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="col-12">
              <label className="form-label">Refrão:</label>
              <textarea
                name="refrao"
                value={formData.refrao}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">Momento:</label>
              <input
                type="text"
                name="momento"
                value={formData.momento}
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
          <div className="mt-4 text-center">
            <button type="submit" className="btn btn-primary">
              Cadastrar
            </button>
            <Link to="/HomePage" className="btn btn-secondary mx-2">
              Home
            </Link>
            <Link to="/musicas/encontrar" className="btn btn-secondary">
              Criar Slide
            </Link>
          </div>
        </form>
        {mensagem && (
          <div className="alert alert-info mt-4 text-center">{mensagem}</div>
        )}
      </div>
    </div>
  );
};

export default CadastrarMusica;
