import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Para usar FontAwesome
import {
    faMusic,
    faClock,
    faDatabase,
    faHammer,
} from "@fortawesome/free-solid-svg-icons"; // Ícones de exemplo

function CardBeneficios() {
    return (
        <div className="container mb-5">
            <div className="row row-cols-1 row-cols-md-2 g-4 mt-3">
                {/* Card 1 */}
                <div className="col">
                    <div className="card text-center">
                        <div className="card-body">
                            <FontAwesomeIcon icon={faMusic} size="3x" />
                            <h5 className="card-title mt-3">
                                Facilidade de Uso
                            </h5>
                            <p className="card-text">
                                Interface intuitiva para adicionar músicas
                                rapidamente.
                            </p>
                        </div>
                    </div>
                </div>
                {/* Card 2 */}
                <div className="col">
                    <div className="card text-center">
                        <div className="card-body">
                            <FontAwesomeIcon icon={faClock} size="3x" />
                            <h5 className="card-title mt-3">
                                Economia de Tempo
                            </h5>
                            <p className="card-text">
                                Crie seus slides de forma rápida e eficiente.
                            </p>
                        </div>
                    </div>
                </div>
                {/* Card 3 */}
                <div className="col">
                    <div className="card text-center">
                        <div className="card-body">
                            <FontAwesomeIcon icon={faDatabase} size="3x" />
                            <h5 className="card-title mt-3">
                                Banco de Dados Amplo
                            </h5>
                            <p className="card-text">
                                Acesso a um vasto banco de dados de músicas.
                            </p>
                        </div>
                    </div>
                </div>
                {/* Card 4 */}
                <div className="col">
                    <div className="card text-center">
                        <div className="card-body">
                            <FontAwesomeIcon icon={faHammer} size="3x" />
                            <h5 className="card-title mt-3">Personalização</h5>
                            <p className="card-text">
                                Customize seus slides conforme sua necessidade.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardBeneficios;
