// src/pages/Sobre.js

import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

const Sobre = () => {
  return (
    <div>
      <Header />
      <div className="container">
        <h2 className="text-center mt-5">Sobre o Criador</h2>
        <p>
          Richarlisom Martins de Souza é um músico dedicado da igreja católica, com uma paixão profunda pela música e pela comunidade. Observando a necessidade de simplificar a criação de slides para projeção das letras das músicas durante as celebrações, ele idealizou e desenvolveu esta aplicação.
        </p>
        <p>
          A aplicação foi criada para facilitar a construção de slides, permitindo que os cantores da igreja tenham acesso fácil e prático às letras das músicas. Com um banco de dados extensivo e funcionalidades intuitivas, espera-se que o processo de projeção se torne mais eficiente e envolvente para todos os envolvidos.
        </p>
        <p>
          Richarlisom espera que esta ferramenta não apenas melhore a apresentação das músicas, mas também ajude a enriquecer as celebrações eucarísticas com uma execução musical ainda mais harmoniosa.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Sobre;