import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { API_URL_ORIGINAL, API_URL_TEST } from "../config/apiConfig";
import Header from "../components/Header";

function RedefinirSenha() {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [message, setMessage] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const [token, setToken] = useState("");
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Extrair o token da URL
        const searchParams = new URLSearchParams(location.search);
        const tokenFromUrl = searchParams.get("token");
        if (tokenFromUrl) {
            setToken(tokenFromUrl);
        } else {
            setMessage("Token inválido ou expirado.");
            setTimeout(() => {
                navigate("/login");
            }, 3000);
        }
    }, [location, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage("");

        // Validar se as senhas coincidem
        if (password !== confirmPassword) {
            setMessage("As senhas não correspondem.");
            setIsSuccess(false);
            return;
        }

        // Validar comprimento mínimo da senha
        if (password.length < 6) {
            setMessage("A senha deve ter pelo menos 6 caracteres.");
            setIsSuccess(false);
            return;
        }

        try {
            const response = await fetch(
                `${API_URL_ORIGINAL}/auth/redefinir-senha`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        token,
                        newPassword: password,
                    }),
                },
            );

            const data = await response.json();

            if (response.ok) {
                setIsSuccess(true);
                setMessage("Senha redefinida com sucesso!");
                // Redirecionar para login após 3 segundos
                setTimeout(() => {
                    navigate("/login");
                }, 3000);
            } else {
                setIsSuccess(false);
                setMessage(data.message || "Erro ao redefinir senha.");
            }
        } catch (error) {
            console.error("Erro:", error);
            setIsSuccess(false);
            setMessage("Erro ao processar sua solicitação. Tente novamente.");
        }
    };

    return (
        <div>
            <Header />
            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body">
                                <h2 className="text-center mb-4">
                                    Redefinir Senha
                                </h2>
                                {message && (
                                    <div
                                        className={`alert ${
                                            isSuccess
                                                ? "alert-success"
                                                : "alert-danger"
                                        }`}
                                        role="alert"
                                    >
                                        {message}
                                    </div>
                                )}
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label
                                            htmlFor="password"
                                            className="form-label"
                                        >
                                            Nova Senha:
                                        </label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="password"
                                            value={password}
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                            required
                                            minLength="6"
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label
                                            htmlFor="confirmPassword"
                                            className="form-label"
                                        >
                                            Confirme a Nova Senha:
                                        </label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="confirmPassword"
                                            value={confirmPassword}
                                            onChange={(e) =>
                                                setConfirmPassword(
                                                    e.target.value,
                                                )
                                            }
                                            required
                                            minLength="6"
                                        />
                                    </div>
                                    <div className="d-grid gap-2">
                                        <button
                                            type="submit"
                                            className="btn btn-primary"
                                        >
                                            Redefinir Senha
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            onClick={() => navigate("/login")}
                                        >
                                            Voltar para Login
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RedefinirSenha;
