import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL_ORIGINAL } from "../config/apiConfig"; // Certifique-se de importar a configuração correta
import BackgroundAnimado from "../components/BackgroundAnimado";
import "../styles/LoginPage.css";
import Header from "../components/Header";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const isAuthenticated = !!localStorage.getItem("token"); // Verifica se o token está presente

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/homepage"); // Redireciona se já estiver autenticado
    }
  }, [isAuthenticated, navigate]);

  const handleLogin = async (event) => {
    event.preventDefault(); // Previne o comportamento padrão do formulário
    setErrorMessage(""); // Limpa mensagens de erro

    try {
      const response = await fetch(API_URL_ORIGINAL + "/auth/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("token", data.token); // Armazena o token recebido do backend
        localStorage.setItem("username", data.username);
        localStroage.setItem("email", data.email);

        navigate("/HomePage"); // Redireciona para a HomePage após o login
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || "Erro ao fazer login.");
      }
    } catch (error) {
      console.error("Erro de autenticação:", error);
      setErrorMessage("Erro de rede. Tente novamente.");
    }
  };

  return (
    <div>
      <Header />
      <div className="login-container">
        <BackgroundAnimado />
        <div className="login-form">
          <h2 className="text-center mb-4">Login</h2>
          {errorMessage && (
            <p className="text-danger text-center">{errorMessage}</p>
          )}
          <form
            onSubmit={handleLogin}
            className="bg-light p-4 rounded shadow-sm"
          >
            <div className="mb-3">
              <label className="form-label">Email:</label>
              <input
                type="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Senha:</label>
              <input
                type="password"
                className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="btn btn-success w-100">
              Entrar
            </button>
          </form>
          <div className="text-center mt-1">
            <button
              className="btn btn-link p-0"
              onClick={() => navigate("/recuperar-senha")}
            >
              Esqueceu sua senha?
            </button>
          </div>
          <div className="text-center mt-3">
            <p>Ainda não tem uma conta?</p>
            <button
              className="btn btn-link"
              onClick={() => navigate("/cadastrar")} // Navega para a página de cadastro
            >
              Cadastre-se
            </button>
          </div>

          {/* Botão para voltar à Home */}
          <div className="text-center mt-3">
            <button className="btn btn-secondary" onClick={() => navigate("/")}>
              Voltar para a Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
